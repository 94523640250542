@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@100;200;300;400;500;600;700;800&family=Open+Sans:wght@300;400;500;600;700&display=swap');

*{
  margin: 0;
  padding: 0;
  font-family: 'Noto Sans', sans-serif;
}
nav{
  background-color: rgb(30,100,242);
  color: white;
  padding: 20px;
  text-align: center;
}


.container{
  background-color: cornsilk;
  width: 70%;
  margin: auto;
  padding: 4vmax 8vmax;
  min-height: 100vh;

}

.container h1{
  text-align: center;
  margin: 30px;
  font-size: 2.5rem;
}

.container > form{
  display: flex;
  flex-direction: column;
}

input, textarea, button{
  padding: 20px;
  border: 1px solid rgba(0,0,0,0.214);
  border-radius: 5px;
  margin: 10px 0;
  font-size: 1.2rem;
}
button{
  border: none;
  cursor: pointer;
  background-color: rgb(39, 36, 36);
  color: white;
}



.task{
  display: flex;
  background-color: white;
  padding: 20px;
  align-items: center;
  border-radius: 5px;
  margin: 20px 0;
}

.task > div{
  width: 100%;
}


.task span{
  color: grey;
}
.task button {
  border-radius: 100%;
  width: 30px;
  height: 30px;
  background-color: crimson;
  font-size: 1.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.task button:hover{
  background-color: red;
}